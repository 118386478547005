import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import useResetProfileError from "../../hooks/useResetProfileError";
import { checkValidity, isFormValid } from "../../shared/utility";
import Container from "../../hoc/Layout/Container/Container";
import FormControlsCreator from "../../components/FormControlsCreator/FormControlsCreator";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import Error from "../../components/Error/Error";
import classes from "./Auth.module.scss";
import * as actions from "../../store/actions/index";
import HeroBanner from "../../components/HeroBanner/HeroBanner";

const Auth = (props) => {
  const emailInputRef = useRef(null);

  const {
    authRedirectPath,
    onSetAuthRedirectPath,
    onAuth,
    loading,
    error,
    isAuthenticated,
    isEmailVerified,
    onLoad,
    onResendVerificationEmail,
    profile,
  } = props;
  const controlsInitialState = {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        placeholder: "Mail Address",
        autoComplete: "username",
        ref: emailInputRef,
      },
      value: "",
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      touched: false,
      orderIndex: 1,
      inputChangedHandler: "inputChangedHandler",
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Password",
        autoComplete: "password",
      },
      value: "",
      validation: {
        required: true,
        minLength: 6,
      },
      valid: false,
      touched: false,
      orderIndex: 2,
      inputChangedHandler: "inputChangedHandler",
    },
  };

  const [controls, setControls] = useState(controlsInitialState);
  const [isSignup, setIsSignup] = useState(true);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  useEffect(() => {
    if (authRedirectPath !== "/") {
      onSetAuthRedirectPath();
    }
  }, [authRedirectPath, onSetAuthRedirectPath]);

  useResetProfileError();

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...controls,
      [controlName]: {
        ...controls[controlName],
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          controls[controlName].validation
        ),
        touched: true,
      },
    };
    setControls(updatedControls);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (isFormValid(controls)) {
      onAuth(controls.email.value, controls.password.value, isSignup).then(
        () => {
          console.log("hello");
        }
      );
    } else {
      setHasValidationErrors(true);
    }
  };

  const resendEmailSubmitHandler = (event) => {
    event.preventDefault();
    onResendVerificationEmail();
  };

  const switchAuthModeHandler = (e) => {
    e.preventDefault();
    setIsSignup((prevState) => {
      return !prevState;
    });
  };

  const signUp = () => {
    setIsSignup(true);
    emailInputRef.current && emailInputRef.current.focus();
  };
  const login = () => {
    setIsSignup(false);
    emailInputRef.current && emailInputRef.current.focus();
  };

  let form = (
    <FormControlsCreator
      showValidationSummary={hasValidationErrors}
      controls={controls}
      eventHandlers={{
        inputChangedHandler,
      }}
    />
  );

  if (loading) {
    form = <Spinner />;
  }

  let errorMessage = null;

  if (error) {
    errorMessage = <Error errors={[error]} />;
  }

  let redirect = null;
  if (!profile.loading) {
    if (
      isAuthenticated &&
      isEmailVerified &&
      profile.completedOnBoarding === "false"
    ) {
      redirect = <Redirect to={"/onboarding"} />;
    } else if (isAuthenticated && isEmailVerified) {
      redirect = <Redirect to={"/listings"} />;
    }
  }

  return (
    <>
      {/* <div className={classes.Auth}> */}
      <Container>
        {/* <h1 className={classes.WelcomeHeader}>
          Welcome to RCSHAK.com – Your Ultimate Destination for RC Enthusiasts!
        </h1> */}
        <HeroBanner signUp={signUp} login={login} />
        <>
          {isAuthenticated && !isEmailVerified && (
            <>
              <div>
                Please check your email and verify your email address to
                complete your registration!
              </div>
              <form onSubmit={resendEmailSubmitHandler}>
                <Button>-- RESEND --</Button>
              </form>
            </>
          )}
          <>
            {redirect}

            <p className={classes.LoginHeader}>
              {isSignup ? "Sign up" : "Log in"}
            </p>
            {errorMessage}
            <form onSubmit={submitHandler}>
              {form}
              <Button
                classNames={`${hasValidationErrors ? "btn_disabled" : ""}`}
              >
                {isSignup ? "Sign up" : "Login"}
              </Button>
              <div className={classes.Authform_links}>
                <Link to="/forgottenEmail">Forgotten password?</Link>
              </div>
              <div className={classes.Authform_links}>
                <Link to="#" onClick={switchAuthModeHandler}>
                  {isSignup ? (
                    <div>
                      Already a member? - <strong>LOG IN</strong>
                    </div>
                  ) : (
                    <div>
                      Become a member? - <strong>SIGN UP</strong>
                    </div>
                  )}
                </Link>
              </div>
            </form>
          </>
          {/* <section className={classes.Aboutus}>
            <h2>About us</h2>

            <p>
              At RCSHAK.com, we are fueled by our passion for remote control
              (RC) vehicles and the vibrant community that shares this
              enthusiasm. Founded by a pair of RC aficionados, our platform is a
              global hub designed to connect, inspire, and serve RC enthusiasts
              from all corners of the world.
            </p>

            <h3>Our Mission</h3>

            <p>
              Our mission is simple: to create a dynamic online community where
              RC lovers can come together to share, explore, and indulge in
              their passion. Whether you’re a seasoned pro or new to the world
              of RC vehicles, RCSHAK.com is your go-to destination for
              everything RC.
            </p>

            <h3>What We Offer</h3>

            <h4>1. Exclusive Registration for RC Enthusiasts:</h4>
            <p>
              Join our growing community by registering on RCSHAK.com. Share
              your experiences, connect with like-minded individuals, and be
              part of our global RC family.
            </p>

            <h4>2. Showcase and Trade:</h4>
            <p>
              Our platform enables you to showcase your prized RC vehicles and
              parts. Upload images, share your collection, and even find buyers
              if you wish to sell. It's a marketplace and a showcase, all in
              one.
            </p>

            <h4>3. Virtual Garage:</h4>
            <p>
              A unique feature that sets us apart! Registered users can create
              their own virtual garage, a personalized space to highlight a
              selection of their RC collection. Its more than just a display;
              its a window into your RC world.
            </p>

            <h4>4. Search and Discover:</h4>
            <p>
              Looking for something specific? Browse RCSHAK.com to find a wide
              array of RC vehicles and parts. Our user-friendly search function
              makes it easy to find exactly what you need.
            </p>

            <h3>Coming Soon: Enhanced Garage Features!</h3>
            <p>
              Were constantly evolving, and soon, your virtual garage will boast
              advanced features like showcasing vehicle upgrades, sharing parts
              sources, listing your favorite RC YouTubers, and much more.
            </p>

            <h3>Join Us on Our Journey</h3>

            <p>
              <a href="https://www.RCSHAK.com">RCSHAK.com</a> is more than just
              a website; its a community, a marketplace, and a treasure trove of
              RC knowledge and resources. Whether you want to show off your
              latest build, seek advice, or just admire the vast array of RC
              vehicles, you're in the right place.
            </p>
          </section> */}
        </>
      </Container>
      {/* </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    isEmailVerified: state.auth.emailVerified == true,
    authRedirectPath: state.auth.authRedirectPath,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, isSignup) =>
      dispatch(actions.auth(email, password, isSignup)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
    onResendVerificationEmail: () =>
      dispatch(actions.resendVerificationEmail("/")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
