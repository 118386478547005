import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";

import Button from "../Button/Button";

const HeroBanner = ({ signUp, login }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      // navigation
      // pagination={{ clickable: false }}
      autoplay={{ delay: 5000 }}
      loop={true}
      className="hero-swiper"
    >
      {/* Slide 1 */}
      <SwiperSlide>
        <div
          className="hero-slide"
          // style={{ backgroundImage: "url('/path-to-image1.jpg')" }}
        >
          <div className="hero-content">
            <h1>Welcome to RCSHAK</h1>
            <p>
              At RCSHAK.com, we are fueled by our passion for remote control
              (RC) vehicles and the vibrant community that shares this
              enthusiasm. Founded by a pair of RC aficionados, our platform is a
              global hub designed to connect, inspire, and serve RC enthusiasts
              from all corners of the world.
            </p>
            <div className="cta-buttons">
              {/* <button className="cta-primary">Get Started</button> */}
              <Button clicked={signUp}>Get Started</Button>
              {/* <button className="cta-secondary">Learn More</button> */}
              <Button clicked={login}>Log in</Button>
            </div>
          </div>
        </div>
      </SwiperSlide>

      {/* Slide 2 */}
      <SwiperSlide>
        <div
          className="hero-slide"
          // style={{ backgroundImage: "url('/path-to-image2.jpg')" }}
        >
          <div className="hero-content">
            <h1>Our mission</h1>
            <p>
              Our mission is simple: to create a dynamic online community where
              RC lovers can come together to share, explore, and indulge in
              their passion. Whether you’re a seasoned pro or new to the world
              of RC vehicles, RCSHAK.com is your go-to destination for
              everything RC.
            </p>
            <div className="cta-buttons">
              {/* <button className="cta-primary">Sign Up</button> */}
              <Button clicked={signUp}>Sign Up</Button>
              {/* <button className="cta-secondary">Contact Us</button> */}
              <Button clicked={login}>Log in</Button>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default HeroBanner;
